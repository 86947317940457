import httpService, {baseURL} from "../../services/httpService"
import {apiCall} from "./action"
import {toast} from "react-toastify"

const apiMiddleware = ({dispatch}) => (next) => (action) => {
    if (action.type !== apiCall.type) return next(action)

    const { url, onSuccess, onError, method, data, isSuccessToast, successMessage, isRequestSuccessful} = action.payload
    httpService._request({ baseURL, url, method, data})
        .then(response => {
            console.log('response', response)
            // success case
            if (response.status === 200 && response.data.statusCode === 200) {
                // If it is true then toast message on success is shown
                if (isSuccessToast) {
                    successMessage ? toast.success(successMessage) : toast.success(response.data.message)
                }

                // success action to get data from backend
                if (onSuccess) {
                    dispatch({type: onSuccess, payload: response.data})
                }

                // it only check that request is successful or not
                if (isRequestSuccessful) {
                    dispatch({type: isRequestSuccessful, payload: true})
                }

            } else {
                //general Error Action
                toast.error(response.data.message)

                // it only check that request is successful or not
                if (onError) dispatch({type: onError, payload: true})
            }
        }).catch(error => {
        console.log('err', error)
        if (onError) dispatch({type: onError, payload: true})
        toast.error(error.message)
    })
}

export default apiMiddleware
