import {createAction} from '@reduxjs/toolkit'
const initialState = {
    data: [],
    miscData: {},
    isLoading: false
}

export const setReports = createAction("reportReducer/setReports")
export const setReport = createAction("reportReducer/setReport")
export const setLoading = createAction("reportReducer/setLoading")
export const editReport = createAction("reportReducer/editReport")

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case setReports.type:
            return {
                ...state,
                data: action.payload.data,
                miscData: action.payload.miscData,
                isLoading: false
            }
        case setReport.type:
            return {
                ...state,
                data: action.payload.data,
                miscData: action.payload.miscData,
                isLoading: false
            }
        case setLoading.type:
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state
    }
}

export default reportReducer
